.rmsc {
  --rmsc-main: rgb(211, 211, 211);
}

.shim-red {
  position: relative;
  overflow: hidden;
  background-color: #ebebeb;
}

.shim-red::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgb(72, 52, 143) 0,
      rgb(72, 52, 143) 70%,
      rgb(72, 52, 143) 100%);
  animation: shimmer 3s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

.recharts-surface {
  overflow: visible;
}

.filepond--drop-label {
  background: white !important;
  border-top: 2px dotted #ebebeb !important;
  border-bottom: 1px solid #ebebeb !important;
  border-left: 1px solid #ebebeb !important;
  border-right: 1px solid #ebebeb !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.filepond--credits {
  display: none;
}

.filepond--root,
.filepond--panel .filepond--panel-root {
  background: white !important;
}