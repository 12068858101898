/* .kep-login-facebook {
  background: #4f4999 !important;
  font-family: "Inter", sans-serif;
  color: #fff;
  border: 1px solid #3b5998;
  border-radius: 3px;
  padding: 7px 14px !important;
  text-transform: none !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-decoration: none;
}
 */

.my-facebook-button-class {
  background-color: #4f46e5 !important;
  color: #fff;
  border-radius: 3px;
  padding: 8px 16px !important;
  text-transform: none !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.my-facebook-button-class::before {
  /*   content: "";
  display: inline-block;
  margin-right: 10px;
  background: url("/public/assets/icons/facebook-white.png") no-repeat;
  background-size: 23px;
  width: 23px;
  height: 23px; */
}

.my-facebook-button-class-disconnect {
  border-radius: 3px;
  padding: 8px 16px !important;
  text-transform: none !important;
  border-radius: 6px !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.my-facebook-button-class-disconnect::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  background: url("/public/assets/icons/facebook.svg") no-repeat;
  background-size: 23px;
  width: 23px;
  height: 23px;
}
