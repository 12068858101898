@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./styles/calendar.css";
@import "./styles/common.css";
@import "./styles/react-calendar.css";
@import "./styles/date-range.css";
@import "./styles/integration.css";
@import "./styles/style.css";
@import "./styles/buttons.css";
@import "/node_modules/react-toastify/dist/ReactToastify.css";

@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css");

@import url("/node_modules/intro.js/introjs.css");

@layer base {
  html {
    font-family: "Inter", sans-serif;
    /* Inter font */
    overflow-y: scroll;
  }
}

@layer utilities {
  .custom-scroll::-webkit-scrollbar {
    width: 2px;
    background-color: #f1f1f1;
    height: 8px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
    background-color: #d1d5db;
  }

  .bg-linear {
    background: linear-gradient(to right, #f3f1f9 0%, #9490ca 35.39%, #4f4999 77.74%);
  }

  .bg-linear-l {
    background: linear-gradient(to left, #f3f1f9 0%, #9490ca 35.39%, #4f4999 77.74%);
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 40px;
    border-color: transparent transparent transparent #4f4999;
  }

  .triangle-gray {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 40px;
    border-color: transparent transparent transparent #c8c2e2;
  }

  .triangle-white {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 40px;
    border-color: transparent transparent transparent white;
  }
}

/* Stepper Attributes */
/* .MuiStepLabel-labelContainer:before {
  content: "\a0";
  display: block;
  padding: 2px 0;
  line-height: 1px;
}
 */

.MuiStepLabel-label {
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0px !important;
}

.MuiStepLabel-iconContainer {
  z-index: 50 !important;
}

/* .MuiStepIcon-root.Mui-active {
  color: #1976d2 !important;
} */

.MuiStepLabel-root {
  align-items: flex-start !important;
}

/* --------- */

.MuiStepLabel-root {
  width: 100% !important;
}

.css-8t49rw-MuiStepConnector-line {
  min-height: 12px !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 2px;
  background-color: #f1f1f1;
  height: 4px;
}

.bold-custom-scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.bold-custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.bold-custom-scroll::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}

/* Handle on hover */
.bold-custom-scroll::-webkit-scrollbar-thumb:hover {
  background: rgb(167, 167, 167);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(167, 167, 167);
}

/* input[type="file"] {
  display: none;
}
 */

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}

.PhoneInputInput {
  outline: none !important;
}

.introjs-tooltip {
  min-width: 400px !important;
}

input#task-date {
  position: relative;
}

input[type="date"]#task-date::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 5px;
  top: 8px;
  width: auto;
}