/* styles.css */
p {
    color: var(--gray-500, #6B7280);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.reportTable {
    border: 1px solid #E5E7EB;
    border-radius: 10px 10px 0px 0px;
}

.reportTable thead {
    background-color: #EEF2FF;
}

.centered {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.light-blue-500 {
    color: #0EA5E9;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    width: 40%;
    /* Set width as a percentage of the parent */
    height: 50%;
    /* Set height as a percentage of the parent */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    overflow: auto;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h4 {
    margin: 0;
}

.modal-header button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}