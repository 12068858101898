@import "../../node_modules/react-calendar/dist/Calendar.css";

.react-calendar {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  border: 1px solid #eaeaea;
  border-radius: 5px !important;
}

.react-calendar__navigation {
  background: #f3f1f9;
  border: none;
  margin-bottom: 0;
}

.react-calendar__navigation__label {
  background: white !important;
}

.react-calendar__navigation button,
.react-calendar__navigation__arrow .react-calendar__navigation__next-button {
  /* display: white !important; */
  background: #fff !important;
}

.react-calendar__navigation__label__labelText {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.react-calendar__tile--active {
  background: #3b82f6 !important;
  color: white;
  flex: 1;
}

.react-calendar__navigation__label__labelText {
  font-size: 12px;
  font-weight: 500;
}

.react-calendar__navigation__prev2-button {
  display: none !important;
}

/* .react-calendar__month-view__weekdays__weekday {
  display: none !important;
}
 */

.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__next1-button {
  display: none !important;
}

.react-calendar__tile--now {
  background: #4f46e5 !important ;
  color: white;
  flex: 1;
}
