.setButton {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 11px;

    width: 160px;
    height: 30px;

    /* indigo/400 */
    border: 1px solid #818CF8;
    /* shadow/sm */
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* text-xs/leading-4/font-medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* indigo/700 */
    color: #4338CA;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cancelButton {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 11px;

    width: 160px;
    height: 30px;

    /* indigo/400 */
    border: 1px solid #DC2626;
    /* shadow/sm */
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* text-xs/leading-4/font-medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* indigo/700 */
    color: #DC2626;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}