@import "../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";

.rbc-time-content {
  display: none;
}

.rbc-time-header {
  height: 100%;
}

.rbc-label {
  display: none;
}

.rbc-event-label {
  display: none !important;
}

.rbc-time-view,
.rbc-month-view {
  border-radius: 5px;
}

span[role="columnheader"] {
  font-size: 12px !important;
}
.rbc-button-link {
  font-size: 12px !important;
}

.rbc-toolbar {
  display: flex;
}

.rbc-toolbar-label {
  font-size: 14px !important;
  font-weight: 700;
}

.rbc-toolbar button {
  border: 1px solid #ccc;
  border-color: transparent;
  font-size: 14px;
}

.rbc-event {
  background-color: transparent;
  color: #4f4999;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  border-radius: 3px;
  overflow: hidden;
  border: 0.5px solid #ccc;
}

.rbc-btn-group button {
  border: 1px solid #c2c2c2;
  font-size: 14px;
}

.rbc-event:hover {
  opacity: 0.8;
}

.rbc-button-link {
  transition: all 0.2s ease-in-out;
}

.rbc-button-link:hover {
  font-weight: 600;
}

.rbc-row-segment {
  margin-bottom: 0px !important;
}

.rbc-event.rbc-selected {
  background-color: transparent;
}

.rbc-event:active {
  background-color: transparent;
}

.rbc-active {
  box-shadow: none !important;
}

.rbc-show-more {
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease;
}

.rbc-show-more:hover {
  opacity: 0.8;
}

.rbc-agenda-table > thead > tr > th:nth-child(2) {
  display: none;
}

.rbc-agenda-time-cell {
  display: none;
}

.rbc-header {
  background-color: #f3f1f9;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  padding: 0;
}

.rbc-calendar {
  height: 650px !important;
  background-color: white;
}

.rbc-allday-cell {
  overflow: auto;
}

.rbc-toolbar {
  background-color: #f1f5f9;
  margin-bottom: 0;
  padding-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .rbc-toolbar {
    flex-direction: column;
    gap: 10px;
  }

  /*   .rbc-event-content {
    display: none;
  }

  .rbc-show-more {
    font-size: 65%;
  } */

  .rbc-event {
    font-size: 12px;
  }

  .rbc-day-bg {
    max-width: 100px;
  }
}
